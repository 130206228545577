import { Backdrop, Box, Button, CircularProgress, Input, Modal } from "@mui/material";
import { useStyles } from "components/VendorCapabilitiesUpdate/constant";
import { JobRequestActions } from "container/JobRequests/actions";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Typography } from "venwiz-ui";
import { FORM_DATA_KEY } from "../../jobRequest.constants";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ComparisonDetails = () => {
  const { _id } = useParams<{ _id: string }>();
  const dispatch = useDispatch();
  const [comparisonData, setComparisonData] = useState<any>();
  const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [showBOQHistory, setShowBOQHistory] = useState<boolean>(false); // State to handle BOQ history view
  const [revisedQuote, setRevisedQuote] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [vendorFiles, setVendorFiles] = useState<any>()
  const [vendorFileName, setVendorFileName] = useState<any>({});

  const toggleAccordion = (index: number) => {
    setOpenRowIndex(openRowIndex === index ? null : index);
  };

  const handleRowClick = (vendor: any) => {
    setSelectedVendor(vendor);
    setShowBOQHistory(false); // Reset BOQ history view when vendor is selected
  };

  const closeRightContainer = () => {
    setSelectedVendor(null);
    setShowBOQHistory(false); // Close BOQ history view when container is closed
  };

  const addRevisedQuote = (e: any) => {
    setRevisedQuote(e.target.files[0]);
  };

  const submitRevisedQuote = (vendor: any) => {
    const formData = new FormData();
    formData.append(FORM_DATA_KEY, revisedQuote);
    formData.append("payload", JSON.stringify({ ...vendor, id: _id }));
    setIsLoading(true);
    dispatch(JobRequestActions.updateVendorQuote(formData, { ...vendor, id: _id })
    ).then(() => {
      refresh();
      setIsLoading(false);
      setRevisedQuote({});
    });
  };

  const addNewVendorQuote = () => {
    setIsModalOpen(true)
  }

  useEffect(() => {
    dispatch(JobRequestActions.getComparisonById(_id))
      .then((data: any) => {
        setComparisonData(data);
      });
  }, []);

  const refresh = () => {
    dispatch(JobRequestActions.getComparisonById(_id))
      .then((data: any) => {
        setComparisonData(data);
      });
  };

  const handleBOQHistoryClick = () => {
    setSelectedVendor(null); // Reset vendor view
    setShowBOQHistory(true); // Show BOQ history
  };

  const editSheet = (url: string) => {
    setIsLoading(true)
    dispatch(JobRequestActions.makeSheetEditable({ url, id: _id }))
      .then((data: any) => {
        setTimeout(() => {
          setIsLoading(false); // Hide loading after 10 seconds
          window.open(url, '_blank');
          refresh()
        }, 1000);
      });
  }

  const submitVendorFiles = (e: any) => {
    setVendorFiles(e.target.files)
  }

  const handleVendorNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedName = { ...vendorFileName };
    updatedName[vendorFiles[index].name] = e.target.value;
    setVendorFileName(updatedName);
  };

  const submitVendorQuotes = () => {
    const formData = new FormData();
    Array.from(vendorFiles).map((file: any) => {
      formData.append(FORM_DATA_KEY, file);
    })
    formData.append("payload", JSON.stringify({ id: _id, vendorFileName }));
    setIsLoading(true);
    dispatch(JobRequestActions.addNewVendorQuotes(formData, { id: _id, vendorFileName }))
      .then((data: any) => {
        setTimeout(() => {
          clearModal();
          setIsLoading(false); // Hide loading after 10 seconds
        }, 1000);
      });
  }
  const getVendorName = (quote: any) => {
    const fileKey = Object.keys(comparisonData.comparison_constant[0].vendorFileName).find(v => v === quote.name);
    return fileKey ? comparisonData.comparison_constant[0].vendorFileName[fileKey] : ""
  }
  const clearModal = () => {
    setVendorFiles([])
    setIsModalOpen(false)
    setVendorFileName([])
  }

  const recreateComparison = () => {
    setIsLoading(true);
    dispatch(JobRequestActions.recreateComparison({ id: _id, url: comparisonData.subcategory_sheet }))
      .then((data: any) => {
        setTimeout(() => {
          setIsLoading(false);
          refresh()
        }, 1000);
      });
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      < Modal open={isModalOpen} onClose={(event, reason) => {
        // Prevent closing on clicking the backdrop or pressing the 'Escape' key
        if (reason === "backdropClick") {
          return;
        }
        setIsModalOpen(false);
      }}>
        <Box
          style={{
            width: "500px",
            margin: "100px auto",
            backgroundColor: "white",
            maxHeight: "80vh", // Limit height to 80% of the viewport
            overflowY: "auto", // Enable vertical scrolling
            padding: "20px",
            textAlign: "left",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            position: "relative",
            justifyItems: "center",
            display: "grid"
          }}
        > <Box style={{ width: "100%", padding: "30px" }}>
            <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
              <Button variant="contained" style={styles.button} component="label">

                Upload Vendor Offers
                <input type="file" multiple={true} hidden onChange={submitVendorFiles} />
              </Button>
            </div>
            {!!vendorFiles?.length && <Typography>No. of Vendor Quotes uploaded: {vendorFiles?.length}</Typography>
              &&
              <Box padding={"10px"}>
                <Typography padding={"10px"}> Please enter vendor names corresponding to files: </Typography>
                {Array.from(vendorFiles)?.map((file: any, index: number) => (
                  <Flex padding={"10px"}>
                    <Typography>{file.name}</Typography>
                    <Input
                      style={{ padding: "0", margin: "0px 30px", width: "40%" }}
                      onChange={(e: any) => handleVendorNameChange(e, index)}
                      value={file.vendorName}></Input>
                  </Flex>
                ))}
              </Box>
            }
          </Box>
          {!!vendorFiles?.length && <Button style={styles.button} onClick={submitVendorQuotes}>
            Add Vendor Quotes and update Comparison
          </Button>}
        </Box>

      </Modal >
      <div style={styles.container}>
        {/* Left Container */}
        <div style={{ ...styles.column, width: selectedVendor || showBOQHistory ? '48%' : '100%' }}>
          <h1>Comparison details: </h1>
          <a target="_blank" style={styles.viewChip} href={comparisonData?.result}>View LATEST Versions Comparison</a>
          {!!comparisonData?.result_with_versions && <a target="_blank" style={styles.viewChip} href={comparisonData?.result_with_versions}>View All Versions Comparison</a>}


          <p>BOQ:
            <a target="_blank" style={styles.viewChip} href={comparisonData?.boq?.url}>View</a>
          </p>
          <p>Category Sheet: <a target="_blank" style={styles.viewChip} href={comparisonData?.subcategory_sheet}>View</a>
            <a target="_blank" style={styles.editChip} onClick={() => { editSheet(comparisonData?.subcategory_sheet) }}>Edit</a>
            {!!comparisonData?.subcategory_edit && <a target="_blank" style={styles.editChip} onClick={() => { recreateComparison() }}>Freeze Edit &  Re-create Comparison</a>}
          </p>

          {comparisonData?.comparison_constant?.map((sheet: any) => (
            <div key={sheet?.name}>
              <p style={{ textDecoration: "bold" }}>Sheet Name: {sheet.name}</p>
              <p>Header Row Number: {sheet.headerRow}</p>
              <p>Columns to fill by Vendor:</p>
              <div style={styles.chipContainer}>
                {sheet.vendorToFill.map((chip: string, index: number) => (
                  <div key={index} style={styles.chip}>
                    {chip}
                  </div>
                ))}
              </div>
              {/* Button to show BOQ history */}
              <Flex style={{ justifyContent: "space-between", maxWidth: "425px" }}>
                <Button style={styles.button} onClick={handleBOQHistoryClick}>
                  Show Comparison History
                </Button>
                <Button style={styles.button} onClick={addNewVendorQuote}>
                  Add new Vendor Quote
                </Button>
              </Flex>
            </div>
          ))}

          <hr />
          <h1>Vendor Quotes: </h1>

          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>File Name</th>
                <th style={styles.th}>Vendor Name</th>
                <th style={styles.th}>Quote Version</th>
                <th style={styles.th}>Quote Link</th>
                <th style={styles.th}>Uploaded At</th>
              </tr>
            </thead>
            <tbody>
              {comparisonData?.vendor_quotes_name?.map((quote: any, index: number) => (
                <tr key={index} onClick={() => handleRowClick(quote)} style={styles.row}>
                  <td style={styles.td}>{quote.name}</td>
                  <td style={styles.td}> {getVendorName(quote)} </td>
                  <td style={styles.td}>{quote?.versions?.length + 1 || 1}</td>
                  <td style={styles.td}>
                    <a href={quote.url} target="_blank">View Quote</a>
                  </td>
                  <td style={styles.td}>{moment(quote.updatedAt).format("DD-MM-YYYY - hh:mm A")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Right Container for Vendor or BOQ History */}
        {(selectedVendor || showBOQHistory) && (
          <div style={styles.rightColumn}>
            <button style={styles.closeButton} onClick={closeRightContainer}>X</button>
            {showBOQHistory ? (
              <>
                <h2> Comparison History</h2>
                <table style={styles.table}>
                  <thead>
                    <tr>
                      <th style={styles.th}>BOQ Version</th>
                      <th style={styles.th}>View</th>
                      <th style={styles.th}>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {comparisonData?.previous_comparisons?.map((comparison: any, index: number) => (
                      <tr key={index} style={styles.row}>
                        <td style={styles.td}>Version {index + 1}</td>
                        <td style={styles.td}>
                          <a href={comparison.url} target="_blank" rel="noopener noreferrer">View</a>
                        </td>
                        <td style={styles.td}>{moment(comparison.createdAt).format("DD-MM-YYYY - hh:mm A")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                {/* Vendor Details Section (existing) */}
                  <h2>Vendor Details</h2>
                  <p>Quote Details for {selectedVendor.name}</p>
                  <div style={styles.buttonContainer}>
                    <Button variant="contained" component="label" style={styles.button}>
                      Add Revised Quote
                      <input type="file" hidden onChange={addRevisedQuote} />
                    </Button>
                    {!!revisedQuote?.name && (
                      <>
                        <Typography style={styles.typography}>{revisedQuote.name}</Typography>
                        <button style={styles.delButton} onClick={() => setRevisedQuote({})}>X</button>
                      </>
                    )}
                    <Button
                      disabled={!revisedQuote?.name}
                      variant="contained"
                      style={!revisedQuote?.name ? {} : styles.button}
                      onClick={() => submitRevisedQuote(selectedVendor)}
                    >
                      Submit
                    </Button>
                  </div>

                  {/* Vendor Quote History */}
                  <Accordion style={{ marginTop: "10px" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography>View Vendor Quote History</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table style={styles.table}>
                        <thead>
                          <tr>
                            <th style={styles.th}>Version</th>
                            <th style={styles.th}>View</th>
                            <th style={styles.th}>Uploaded At</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={"FIRST-ROW"} style={styles.row}>
                            <td style={styles.td}>LATEST</td>
                            <td style={styles.td}>
                              <a href={selectedVendor.url} target="_blank" rel="noopener noreferrer">View</a>
                            </td>
                            <td style={styles.td}>{moment(selectedVendor.uploadedAt).format("DD-MM-YYYY - hh:mm A")}</td>
                          </tr>
                          {selectedVendor?.versions
                            ?.sort((a: any, b: any) => new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime())
                            ?.map((version: any, versionIndex: number) => (
                              <tr key={versionIndex} style={styles.row}>
                                <td style={styles.td}>{versionIndex + 1}</td>
                                <td style={styles.td}>
                                  <a href={version.url} target="_blank" rel="noopener noreferrer">View</a>
                                </td>
                                <td style={styles.td}>{moment(version.uploadedAt).format("DD-MM-YYYY - hh:mm A")}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </AccordionDetails>
                </Accordion>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    padding: '20px',
    height: '100vh',
    boxSizing: 'border-box' as 'border-box'
  },
  column: {
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    boxSizing: 'border-box' as 'border-box',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    gap: '10px',
    // marginTop: '20px',
  },
  chip: {
    padding: '4px 8px',
    backgroundColor: '#E0F7FA',
    borderRadius: '8px',
    color: '#00796B',
    fontSize: '10px',
    fontWeight: 'bold' as 'bold',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'inline-block',
    marginBottom: "10px",
  },
  viewChip: {
    padding: '5px 30px',
    backgroundColor: '#DAF0FF',
    borderRadius: '3px',
    color: "#0077B6",
    fontSize: '12px',
    fontWeight: 'bold' as 'bold',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'inline-block',
    margin: "0px 0px 10px 10px",
  },
  editChip: {
    padding: '5px 30px',
    backgroundColor: '#CEFAD0',
    borderRadius: '3px',
    color: 'green',
    fontSize: '12px',
    fontWeight: 'bold' as 'bold',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'inline-block',
    margin: "0px 0px 0px 10px",
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse' as 'collapse',
    marginBottom: '20px',
  },
  th: {
    borderBottom: '2px solid #ddd',
    textAlign: 'left' as 'left',
    padding: '12px',
    backgroundColor: '#f2f2f2',
    fontSize: '14px',
    color: '#00796B',
    fontWeight: 'bold' as 'bold',
  },
  td: {
    borderBottom: '1px solid #ddd',
    padding: '12px',
    fontSize: '12px',
    color: '#4A4A4A',
  },
  row: {
    cursor: 'pointer',
    color: '#00796B',
    fontSize: '12px',
  },
  button: {
    padding: '6px 12px',
    backgroundColor: '#00796B',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '12px',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',

  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '20px',
  },
  typography: {
    fontSize: '12px',
    color: '#4A4A4A',
    textAlign: 'center',
  },
  accordion: {
    backgroundColor: '#f0f0f0',
    padding: '16px',
    border: '1px solid #ddd',
    textAlign: 'center' as 'center',
    color: '#4A4A4A',
    fontSize: '12px',
  },
  rightColumn: {
    width: '48%',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    boxSizing: 'border-box' as 'border-box',
    position: 'relative' as 'relative', // To posit
  },
  closeButton: {
    position: 'absolute' as 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '18px',
    color: '#00796B',
    cursor: 'pointer',
  },
  delButton: {
    backgroundColor: 'red',
    border: 'none',
    borderRadius: "8px",
    fontSize: '8px',
    color: '#fff',
    cursor: 'pointer',
  }
};
export default ComparisonDetails;
